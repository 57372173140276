import {SET_CATEGORIES, SET_GAME, SET_GAME_URL, SET_GAMES, SET_PRODUCTS_SEARCH_RESULT} from "@actions/actionTypes";
import {GetGameUrl, LoadCategories, LoadGame, LoadGames, Search} from "@services/ProductsService";

export function loadCategories() {
    return dispatch => {
        return LoadCategories()
            .then(categories => {
                dispatch({type: SET_CATEGORIES, categories});
            });
    }
}
export function loadGames(categoryId) {
    return dispatch => {
        return LoadGames(categoryId)
            .then(games => {
                dispatch({type: SET_GAMES, games});
            });
    }
}
export function loadGame(gameId) {
    return dispatch => {
        return LoadGame(gameId)
            .then(game => {
                dispatch({type: SET_GAME, currentGame: game});
            });
    }
}
export function removeGameUrl() {
    return dispatch => {
        dispatch({type: SET_GAME_URL, gameUrl: null})
        dispatch({type: SET_GAME, currentGame: {}});
    }
}

export function getGameUrl(slug) {
    return dispatch => {
        return GetGameUrl(slug)
            .then(data => {
                dispatch({type: SET_GAME_URL, gameUrl: data.url});
            });
    }
}

export function searchProducts(searchQuery) {
    return dispatch => {
        return Search(searchQuery)
            .then(games => {
                dispatch({type: SET_PRODUCTS_SEARCH_RESULT, games});
            });
    }
}
