import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {loadBanners} from "@actions/bannerActions";
import classnames from "classnames";
import Loading from "../Loading/Loading";

function BannersSection({loadBanners, banners, user}) {
    useEffect(() => {
        loadBanners()
    }, []);

    const [bannerContent, setBannerContent] = useState(null);
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

    useEffect(() => {
        if (!banners.length) return;
        setBannerContent(banners[currentBannerIndex].content);
    }, [currentBannerIndex, banners]);

    setTimeout(() => {
        const currentIndex = currentBannerIndex + 1;
        const nextBanner = banners[currentIndex];
        if (nextBanner) setCurrentBannerIndex(currentIndex);
        else setCurrentBannerIndex(0);
    }, 10000);

    return (
        <div className={classnames("start-frame", {loading: !bannerContent, logined: user})}>
            {!bannerContent
                ? <Loading/>
                : <div dangerouslySetInnerHTML={{__html: bannerContent}}/>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        banners: state.settings.banners,
        user: state.user
    }
}

const mapDispatchToProps = {
    loadBanners
}

export default connect(mapStateToProps, mapDispatchToProps)(BannersSection);
