import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {loadDepositsHistory} from "@actions/profileActions";
import moment from "moment";
import {FormattedMessage} from "react-intl";
function TabDeposit({loadDepositsHistory, depositsHistory}) {
    useEffect(() => {
        loadDepositsHistory()
    }, []);

    return (
        <div className="tab deposits">
            <div className="right">
                <div className="table">
                    <div className="table-header">
                        <div className="table-header__item">
                            #
                        </div>
                        <div className="table-header__item">
                            <FormattedMessage id="sum"/>
                        </div>
                        <div className="table-header__item">
                            <FormattedMessage id="date"/>
                        </div>
                        <div className="table-header__item">
                            <FormattedMessage id="system"/>
                        </div>
                    </div>
                    {depositsHistory.map((history, index) => {
                        return (
                            <div key={history.id} className="table-body">
                                <div className="table-body__item num">
                                    <span>{index + 1}</span>
                                </div>
                                <div className="table-body__item">
                                    ${history.balanceAfter - history.balanceBefore}
                                </div>
                                <div className="table-body__item">
                                    {moment(history.date).format("DD.MM.YYYY")}
                                </div>
                                <div className="table-body__item">
                                    {history.productName}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        depositsHistory: state.profile.depositsHistory
    };
}

const mapDispatchToProps = {
    loadDepositsHistory
}

export default connect(mapStateToProps, mapDispatchToProps)(TabDeposit);
