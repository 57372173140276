import React from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import {isEmpty} from 'lodash';
import {Link} from "react-router-dom";
import {normalizeUrl} from "../../utils/UrlUtil";

function HeaderMenuContainer({headerMenus}) {
    return (
        <nav className="header__navigation">
            {headerMenus.map(menu => {
                return (
                    <Link key={menu.id} to={menu.url ? `/info${normalizeUrl(menu.url)}` : null} className="header__navigation--link">
                        <svg>
                            <use xlinkHref={`${spirites}#header__${menu.name.toLowerCase()}`}/>
                        </svg>
                        {menu.name}
                    </Link>
                );
            })}
        </nav>
    );
}

function mapStateToProps(state) {
    return {
        headerMenus: state.settings.menu.header.menus
    }
}

export default connect(mapStateToProps)(HeaderMenuContainer);
