import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import spirites from "@assets/img/sprites.svg";
import {loadMenu} from "@actions/menuActions";
import {normalizeUrl} from "../../utils/UrlUtil";
import {FormattedMessage} from "react-intl";

function FooterMenu({loadMenu, footerMenu}) {
    useEffect(() => {
        loadMenu("footer")
    }, []);

    return (
        <div className="section--wrap">
            <div className="col">
                <Link to="/" className="logo"/>
                <div className="copyright">
                    <FormattedMessage id="casino"/> © 2020. <FormattedMessage id="allRightsReserves"/>
                </div>
                <nav className="social__block">
                    <a href="#" className="social__block--link">
                        <svg>
                            <use xlinkHref={`${spirites}#social__facebook`}/>
                        </svg>
                    </a>
                    <a href="#" className="social__block--link">
                        <svg>
                            <use xlinkHref={`${spirites}#social__telegram`}/>
                        </svg>
                    </a>
                    <a href="#" className="social__block--link">
                        <svg>
                            <use xlinkHref={`${spirites}#social__twitter`}/>
                        </svg>
                    </a>
                    <a href="#" className="social__block--link">
                        <svg>
                            <use xlinkHref={`${spirites}#social__google`}/>
                        </svg>
                    </a>
                </nav>
            </div>
            {footerMenu.map((menu, index) => {
                return (
                    <div key={index} className="col">
                        <ul className="menu-list">
                            <li className="menu-list__item menu-list__item--title">{menu.name}</li>
                            {menu.menuItems.map(menuItem => {
                                return (
                                    <li key={menuItem.id} className="menu-list__item">
                                        <Link
                                            to={menuItem.url ? `/info${normalizeUrl(menuItem.url)}` : '#'}
                                            className="menu-list__item--link">
                                            {menuItem.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        footerMenu: state.settings.menu.footer.menus
    }
}

const mapDispatchToProps = {
    loadMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterMenu);