import {SET_BANNERS, SET_TOP_WINNERS} from "@actions/actionTypes";
import {LoadBanners, LoadTopWinners} from "@services/BannersService";

export function loadBanners() {
    return dispatch => {
        return LoadBanners("casino")
            .then(bannerGroup => {
                dispatch({type: SET_BANNERS, banners: bannerGroup.banners})
            });
    }
}

export function loadTopWinners() {
    return dispatch => {
        return LoadTopWinners()
            .then(topWinners => {
                dispatch({type: SET_TOP_WINNERS, topWinners})
            });
    }
}
