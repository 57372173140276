import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import spirites from "@assets/img/sprites.svg";
import fallbackImage from '@assets/img/user-image.jpg'
import {loadGameHistory, updateProfile} from "@actions/profileActions";
import {TransactionCode, TransactionType} from "../../constants/Enums";
import moment from "moment";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";

function TabProfile({user, gameHistory, loadGameHistory, updateProfile}) {

    const [userName, setUserName] = useState('');
    const [isUserNameChanged, setIsUserNameChanged] = useState(false);
    const [showUsernameSave, setShowUsernameSave] = useState(false);

    useEffect(() => {
        loadGameHistory();
    }, []);

    useEffect(() => {
        setUserName(user.userName);
    }, [user.userName])

    const fileSelectRef = React.createRef();
    const usernameSaveRef = React.createRef();

    function handleProfileImageUpdate(e) {
        updateProfile({image: e.target.files[0]});
    }

    function handleUserNameChange(e) {
        setIsUserNameChanged(true);
        setShowUsernameSave(true);
        setUserName(e.target.value);
    }

    function handleUsernameSaveClick(e) {
        e.stopPropagation();
        e.preventDefault();
        usernameSaveRef.current.focus();
        updateProfile({userName});
    }

    return (
        <div className="tab profile">
            <div className="modal__profile" data-micromodal-close>
                <div className="modal__profile__info">
                    <div className="profile__ava">
                        <input type="file" onChange={handleProfileImageUpdate} name="file"
                               accept="image/png, image/jpeg" style={{display: "none"}} ref={fileSelectRef}/>
                        <img src={user.image || fallbackImage} alt=""/>
                        <div className="profile-edit" onClick={() => fileSelectRef.current.click()}>
                            <svg>
                                <use xlinkHref={`${spirites}#edit`}/>
                            </svg>
                            <FormattedMessage id="editPhoto"/>
                        </div>
                    </div>
                    <div className="profile__block">
                        <div className="profile__bio">
                            <div className="username-edit">
                                <input className="username"
                                       value={userName}
                                       ref={usernameSaveRef}
                                       onFocus={() => isUserNameChanged && setShowUsernameSave(true)}
                                       onChange={handleUserNameChange}
                                       onBlur={() => setShowUsernameSave(false)}/>
                                <svg className={classnames({'profile-edit__save--show': showUsernameSave})}
                                     onClick={handleUsernameSaveClick}>
                                    <use xlinkHref={`${spirites}#save`}/>
                                </svg>
                            </div>
                            {user.verified ?
                                <div className="verified">
                                    <svg>
                                        <use xlinkHref={`${spirites}#checked`}/>
                                    </svg>
                                    Verified
                                </div> : null}
                        </div>
                        <div className="profile__data">
                            <div className="item">
                                <span><FormattedMessage id="balance"/> </span>
                                ${user.balance || 0.00}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal__history">
                <div className="section-text">
                    <svg className="section-icon">
                        <use xlinkHref={`${spirites}#podium`}/>
                    </svg>
                    <div className="section-title">
                        <span>
                            <FormattedMessage id="history"/>
                        </span>
                    </div>
                </div>
                <div className="modal__history--table">
                    <div className="modal__history--row">
                        <div className="modal__history--item">
                            <div className="title modal-rate"><FormattedMessage id="rate"/></div>
                            <div className="title modal-game"><FormattedMessage id="game"/></div>
                            <div className="title modal-balance-before"><FormattedMessage id="balanceBefore"/></div>
                            <div className="title modal-balance-after"><FormattedMessage id="balanceAfter"/></div>
                            <div className="title modal-date"><FormattedMessage id="date"/></div>
                            <div className="title modal-status"><FormattedMessage id="status"/></div>
                        </div>
                    </div>
                    {gameHistory.map((history, index) => {
                        return (
                            <div key={history.id} className="modal__history--row">
                                <div className="modal__history--item">
                                    <div className="modal-rate">{index + 1}</div>
                                    <div className="modal-game">
                                        <svg>
                                            <use xlinkHref={`${spirites}#playing-cards`}/>
                                        </svg>
                                        {history.productName}
                                    </div>
                                    <div className="modal-balance-before">${history.balanceBefore}</div>
                                    <div className="modal-balance-after">${history.balanceAfter}</div>
                                    <div className="modal-date">{moment(history.date).format('DD.MM.YYYY - HH:MM')}</div>
                                    <div className={
                                        classnames("modal-status", {
                                            succes: history.type === TransactionType.WIN,
                                            error: history.type === TransactionType.BET,
                                            neutral: history.type === TransactionType.REFUND
                                        })
                                    }>
                                        <svg>
                                            <use xlinkHref={`${spirites}#checked`}/>
                                        </svg>
                                        {TransactionCode[history.type]}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        gameHistory: state.profile.gameHistory
    };
}

const mapDispatchToProps = {
    loadGameHistory,
    updateProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(TabProfile);
