import {LoadUser, Login, LoginExternal, LoginSocial, Logout, RegisterUser, isUpdatedSorageUserData} from "@services/AuthService";
import {
    EXTERNAL_LOGIN_SUCCESS,
    SET_LOGIN_ERROR,
    SET_REGISTRATION_ERROR,
    SET_USER,
    SHOW_LOGIN,
    SHOW_REGISTRATION, USER_LOGGING_OUT
} from "@actions/actionTypes";

export function subscribeStorageChanges(){
    return dispatch => {
        return isUpdatedSorageUserData()
                .subscribe((user) => {
                    dispatch({type: SET_USER, user});
                    dispatch({type: SHOW_LOGIN, showLogin: !user});

        })
    }
}

export function loginUser(email, password) {
    return dispatch => {
        return Login(email, password)
            .then(() => LoadUser())
            .then(user => {
                dispatch({type: SET_USER, user});
                dispatch({type: SHOW_LOGIN, showLogin: false});
            }).catch(error => {
                dispatch({type: SET_LOGIN_ERROR, message: error.message});
                setTimeout(() => {
                    dispatch({type: SET_LOGIN_ERROR, message: null});
                }, 5000)
            });
    }
}

export function logoutUser() {
    return dispatch => {
        Logout();
        dispatch({type: USER_LOGGING_OUT, value: true});
        dispatch({type: SET_USER, user: null});
        setTimeout(() => {
            dispatch({type: USER_LOGGING_OUT, value: false});
        }, 5000);
    }
}

export function registerUser(userData) {
    return dispatch => {
        RegisterUser(userData)
            .then(() => loginUser(userData.email, userData.password)(dispatch))
            .then(() => dispatch({type: SHOW_REGISTRATION, showRegistration: false}))
            .catch(error => {
                dispatch({type: SET_REGISTRATION_ERROR, message: error.message});
                setTimeout(() => {
                    dispatch({type: SET_REGISTRATION_ERROR, message: null});
                }, 5000);
            });
    }
}

export function loginSocial(socialName) {
    return dispatch => {
        return LoginSocial(socialName);
    }
}

export function loginExternal(providerName) {
    return dispatch => {
        return LoginExternal(providerName)
            .then(() => LoadUser())
            .then(user => {

                dispatch({type: SET_USER, user});
                dispatch({type: EXTERNAL_LOGIN_SUCCESS, success: true});
                dispatch({type: SHOW_LOGIN, showLogin: false});
            }).catch(error => {
                dispatch({type: SET_LOGIN_ERROR, message: error.message});
                setTimeout(() => {
                    dispatch({type: SET_LOGIN_ERROR, message: null});
                }, 5000);
            });
    }
}
