import LanguageFactory from "../utils/LanguageFactory";
import {Language} from "../constants/Enums"

export default {
    settings: {
        lang: LanguageFactory(localStorage.getItem("lang") || Language.ENGLISH),
        languages: Object.values(Language).map((x) => LanguageFactory(x)),
        menu: {
            header: {
                menus: []
            },
            footer: {
                menus: [{
                    menuItems: []
                }]
            }
        },
        banners: [],
        topWinners: null,
        info: {},
        modal: {
            showLogin: false,
            showRegistration: false,
            showProfile: false,
            showForgotPassword: false,
            showResetForgotPassword: false
        },
        errors: {
            loginError: null,
            registerError: null
        }
    },
    user: null,
    profile: {
        gameHistory: [],
        depositsHistory: [],
        withdrawalsHistory: [],
        passwordChangeSuccess: false,
        passwordChangeErrorMessage: null,
        verificationSuccess: false,
        forgotPasswordEmailSent: null,
        resetPasswordChanged: null,
        externalLoginSuccess: false,
        isUserLoggingOut: false
    },
    products: {
        categories: [],
        games: [],
        currentGame: {},
        searchResult: [],
        gameUrl: null
    },
    socketSubscriptions: []
};
