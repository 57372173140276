import {GetMenu} from "@services/MenuService";
import {SET_MENU, SET_USER, SUBSCRIBE_USER_UPDATE} from "@actions/actionTypes";
import {LoadUser} from "@services/AuthService";
import {subscribeForUpdates} from "@actions/socketActions";
import {SubscriptionType} from "../../constants/Enums";

export function initialLoad() {
    return (dispatch, getState) => {
        return LoadUser()
            .then(user => {
                dispatch({type: SET_USER, user})
                return user
            })
            .then(user => {
                if (user) {
                    dispatch({
                        type: SUBSCRIBE_USER_UPDATE,
                        subscribe: {
                            keys: [user.id],
                            type: SubscriptionType.USER
                        }
                    });
                }
            })
            .then(() => {
                subscribeForUpdates()(dispatch, getState);
                return GetMenu("header");
            })
            .then(menuGroup => {
                dispatch({type: SET_MENU, menuGroup: menuGroup});
            })
    }
}
