import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant"
import thunk from "redux-thunk";
import SocketClient from "../utils/SocketClient";
import subscriptionMiddleware from "./middlewares/subscriptionMiddleware";
import socketMiddleware from "./middlewares/socketMiddleware";
import {isEmpty} from "lodash";

export default function configureStore(initialState) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

    const socket = SocketClient();
    const middlewares = [
        thunk,
        reduxImmutableStateInvariant(),
        subscriptionMiddleware(socket),
        socketMiddleware(socket)
    ];
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );
    socket.on('connect', function() {
        const subscriptions = store.getState()["socketSubscription"];
        if (!isEmpty(subscriptions)) {
            subscriptions.forEach(subscription => {
                store.dispatch(subscription);
            })
        }
    });
    return store;
}
