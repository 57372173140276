import React from 'react';
import {connect} from 'react-redux';
import HeaderLoginContainer from "../HeaderLoginContainer/HeaderLoginContainer";
import HeaderUserProfile from "../HeaderUserProfile/HeaderUserProfile";

function HeaderUserProfileContainer({user}) {
    return (
        <div className="header__info">
            <div className="header__login">
                {user ? <HeaderUserProfile/> : <HeaderLoginContainer/>}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(HeaderUserProfileContainer);
