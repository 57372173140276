import {SubscriptionType} from "../../constants/Enums";
import {SET_USER} from "@actions/actionTypes";
import {merge, cloneDeep} from 'lodash';
import {transformKeysToLowerCase} from "../../utils/CommonUtils";

export function subscribeForUpdates() {
    return (dispatch, getState) => dispatch({
        event: 'update',
        handle: data => {
            const user = cloneDeep(getState().user);
            if (data.type === SubscriptionType.USER) {
                const updatedData = transformKeysToLowerCase(data.data);
                dispatch({type: SET_USER, user: merge(user, updatedData)})
            }
        }
    });
}
