import React from 'react';
import {connect} from "react-redux";
import classnames from "classnames";
import {GameItem} from "./GameItem";
import Loading from "../Loading/Loading";

function GamesList({games, isLoggedIn}) {
    const isLoading = !games || (games && games.length === 0);
    return (
        <div className={classnames("games-wrap", {loading: isLoading})}>
            {isLoading ? <Loading/>
                : games.map(game => {
                    return (
                        <GameItem
                            key={game.id}
                            id={game.id}
                            name={game.name}
                            description={game.description}
                            image={game.image}
                            isLoggedIn={isLoggedIn}/>
                    );
                })
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        games: state.products.games,
        isLoggedIn: state.user != null
    }
}

export default connect(mapStateToProps)(GamesList);
