import {ADD_USER_SUBSCRIPTION, REMOVE_USER_SUBSCRIPTION} from "@actions/actionTypes";

function removeKeys(allKeys, keysToRemove) {
    const keys = [...allKeys];
    keysToRemove.forEach(key => {
        const index = keys.indexOf(key);
        if (index !== -1) keys.splice(index, 1);
    });
    return keys;
}

export default function subscriptionMiddleware(socket) {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function') return next(action);

        const {subscribe, unsubscribe, type} = action;
        if (!subscribe && !unsubscribe) return next(action);
        const data = {
            type: (subscribe || unsubscribe).type,
            rooms: (subscribe || unsubscribe).keys
        };
        if (subscribe) {
            dispatch({type: ADD_USER_SUBSCRIPTION, data: action});
        } else {
            dispatch({type: REMOVE_USER_SUBSCRIPTION, data: action});
        }
        socket.emit("subscribe", data);
        return dispatch({type, keys: data.rooms});
    };
}
