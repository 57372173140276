import React from "react";
import {connect} from "react-redux";
import spirites from "@assets/img/sprites.svg";
import {loginSocial} from "@actions/userActions";

function SocialLogin({loginSocial}) {

    function handleSocialClick(socialName) {
        loginSocial(socialName)
    }

    return (
        <nav className="social__block">
            <div onClick={() => handleSocialClick("facebook")} className="social__block--link">
                <svg>
                    <use xlinkHref={`${spirites}#social__facebook`}/>
                </svg>
            </div>
            <div onClick={() => handleSocialClick("google")} className="social__block--link">
                <svg>
                    <use xlinkHref={`${spirites}#social__google`}/>
                </svg>
            </div>
        </nav>
    );
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = {
    loginSocial
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLogin);
