import {GetResource, IDP_URL, PostResource} from "../utils/HttpUtil";

let user = JSON.parse(localStorage.getItem("user"));
let access_token = localStorage.getItem("token");

export function GetToken() {
    return access_token;
}

export function RegisterUser(userData) {
    return PostResource("/users/register", userData, IDP_URL);
}

export async function LoadUser() {
    if (!access_token) return;
    const data = await GetResource(
        `/profile`
    ).catch((err) => {
        if (err.message === "401") Logout();
        console.log(err.name, err.message);
    });
    if (data) {
        user = data;
        localStorage.setItem("user", JSON.stringify(user));
    }
    return user;
}

let subscribedStoregeUpdate = () => {};

window.addEventListener('storage', () => {
    let user = JSON.parse(localStorage.getItem("user"));
    access_token = localStorage.getItem("token");
    subscribedStoregeUpdate(user);
});

export function isUpdatedSorageUserData() {
    return {
        subscribe: (f) => {
            subscribedStoregeUpdate = f;
        }
    }
}

export async function Login(username, password) {
    const data = {
        username,
        password,
        scope: "coreapi openid",
        client_id: "casinoapp",
        grant_type: "password",
    };

    const body = Object.keys(data)
        .map((key) => {
            return (
                encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
            );
        })
        .join("&");

    const res = await fetch(
        `${process.env.REACT_APP_API_IDP_PATH}/connect/token`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body,
        }
    );
    const resBody = await res.json();
    if (!res.ok) throw new Error(resBody.error_description || resBody.error || resBody.message);
    access_token = resBody.access_token;
    localStorage.setItem("token", access_token);
}

export function Logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    access_token = null;
    user = null;
}

export async function LoginExternal(providerName) {
    const body = await fetch(`${IDP_URL}/externalAuth/callback/${providerName}`,
        {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
            },
        });
    const res = await body.json();
    access_token = res.access_token;
    localStorage.setItem("token", res.access_token);
}

export function LoginSocial(socialName) {
    window.open(`${IDP_URL}/externalAuth/${socialName}`, "_blank")
}
