import React, {useState} from "react";
import {connect} from "react-redux";
import key from '@assets/img/key.svg'
import classnames from 'classnames';
import {isEmpty} from 'lodash';
import {changePassword} from "@actions/profileActions";
import {FormattedMessage, useIntl} from "react-intl";

function TabSecurity({changePassword, passwordChangeSuccess, passwordChangeErrorMessage}) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [isOldPasswordValid, setIsOldPasswordValid] = useState(true);
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);

    const intl = useIntl();

    function validate() {
        setIsOldPasswordValid(!isEmpty(oldPassword));
        setIsNewPasswordValid(!isEmpty(newPassword) && newPassword === confirmNewPassword);
        setIsConfirmPasswordValid(!isEmpty(confirmNewPassword) && newPassword === confirmNewPassword);
        return isOldPasswordValid && isNewPasswordValid && isConfirmPasswordValid;
    }

    function handleConfirmClick(e) {
        e.preventDefault();
        e.stopPropagation();
        const isValid = validate();
        if (!isValid) return;
        changePassword(oldPassword, newPassword, confirmNewPassword);
    }

    return (
        <div className="tab security">
            <div className="form">
                <div className="item">
                    <input
                        type="password"
                        className={classnames({"error-background": !isOldPasswordValid})}
                        placeholder={intl.formatMessage({id: 'oldPassword'})}
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}/>
                    <img src={key} alt=""/>
                </div>
                <div className="item">
                    <input
                        type="password"
                        className={classnames({"error-background": !isNewPasswordValid})}
                        placeholder={intl.formatMessage({id: 'newPassword'})}
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}/>
                    <img src={key} alt=""/>
                </div>
                <div className="item">
                    <input
                        type="password"
                        className={classnames({"error-background": !isConfirmPasswordValid})}
                        placeholder={intl.formatMessage({id: 'confirmNewPassword'})}
                        value={confirmNewPassword}
                        onChange={e => setConfirmNewPassword(e.target.value)}/>
                    <img src={key} alt=""/>
                </div>
            </div>
            <span className={classnames("infotext", {"success--message": passwordChangeSuccess, "error--message": passwordChangeErrorMessage})}>
                {passwordChangeSuccess ? `* ${intl.formatMessage({id: 'yourPasswordChanged'})}` : null}
                {passwordChangeErrorMessage ? `* ${passwordChangeErrorMessage}` : null}
            </span>
            <div className="security-btn" onClick={handleConfirmClick}>
                <FormattedMessage id="confirm"/>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        passwordChangeSuccess: state.profile.passwordChangeSuccess,
        passwordChangeErrorMessage: state.profile.passwordChangeErrorMessage,
    };
}

const mapDispatchToProps = {
    changePassword
}

export default connect(mapStateToProps, mapDispatchToProps)(TabSecurity);
