import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {isEmpty} from 'lodash';
import {FormattedMessage} from "react-intl";

function SearchBarList({searchProducts, searchResults}) {

    return (
        <div className="search-list">
            {
                isEmpty(searchResults) ? 
                <div className="empty-block">
                    <span>
                        <FormattedMessage id="no_result"/>
                    </span>
                </div>
                :
                searchResults.map(item => (
                    <Link to={`/games/${item.id}`} key={item.id}>
                        <div>
                            <img src={item.image} />
                            <div>
                                <h5>{item.name}</h5>
                                <h6>{item.description}</h6>
                            </div>
                        </div>
                    </Link>
                    )
                ) 
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        searchResults: state.products.searchResult
    };
}

export default connect(mapStateToProps)(SearchBarList);
