import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {loadTopWinners} from "@actions/bannerActions";
import fallbackImage from '@assets/img/user-image.jpg'
import spirites from '@assets/img/sprites.svg'
import moment from "moment";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";
import Loading from "../Loading/Loading";

function TopWinnersSection({loadTopWinners, topWinners}) {
    useEffect(() => {
        loadTopWinners();
    }, []);

    return (
        <div className="info-block">
            <div className="section-text">
                <svg className="section-icon">
                    <use xlinkHref={`${spirites}#topwinner`}/>
                </svg>
                <div className="section-title">
                    <FormattedMessage id="top"/> {}
                    <span>
                        <FormattedMessage id="winners"/>
                    </span>
                </div>
            </div>
            <div className={classnames("top-winners", {loading: !topWinners})}>
                {!topWinners ? <Loading/>
                    : topWinners.map((winner, index) => {
                        return (
                            <div key={index} className="winner-item">
                                <div className="winner-ava"><img src={winner.userImage || fallbackImage} alt=""/></div>
                                <div className="winner-name">
                                    <div className="winner-name__fullname">{winner.userName}</div>
                                    <div className="winner-name__nickname">{winner.productName}</div>
                                </div>
                                <div className="winner-info">
                                    <div className="winner-time">{moment(winner.date).format("HH:MM")}</div>
                                    <div className="winner-balance">${winner.amount}.00</div>
                                </div>
                                <div className={classnames("winner-rate", {
                                    first: index === 0,
                                    second: index === 1,
                                    third: index === 2,
                                    fourth: index === 3,
                                })}>{index + 1}</div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        topWinners: state.settings.topWinners
    }
}

const mapDispatchToProps = {
    loadTopWinners
}

export default connect(mapStateToProps, mapDispatchToProps)(TopWinnersSection);
