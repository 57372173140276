import initialStore from "../initialStore";
import {ADD_USER_SUBSCRIPTION, REMOVE_USER_SUBSCRIPTION, SUBSCRIBE_TO_OLD_ROOMS} from "@actions/actionTypes";
import produce from "immer";
import {isEmpty, isEqual} from "lodash";

export default function socketSubscriptionReducer(state = initialStore.socketSubscriptions, action) {
    switch (action.type) {
        case ADD_USER_SUBSCRIPTION:
            return produce(state, draft => {
                if (isEmpty(action.data)) return;
                const exist = draft.find(data => data.type === action.data.type);
                if (!exist) {
                    draft.push(action.data);
                }
            });
        case REMOVE_USER_SUBSCRIPTION:
            return produce(state, draft => {
                if (!action.data) return;
                const index = draft.findIndex(x => x.type === action.data.type && isEqual(x.rooms, action.data.rooms))
                draft.splice(index, 1);
            });
        case SUBSCRIBE_TO_OLD_ROOMS:
            return produce(state, draft => {
                if (!action.data) return;
                const index = draft.findIndex(x => x.type === action.data.type && isEqual(x.rooms, action.data.rooms))
                draft.splice(index, 1);
            });
        default:
            return state;
    }
}
