import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import InformationSection from "../InformationSection/InformationSection";
import spirites from '@assets/img/sprites.svg'
import {getGameUrl, loadGame, removeGameUrl} from "@actions/productActions";
import Loading from "../Loading/Loading";
import classnames from "classnames";
import {isMobileDevice} from "../../utils/CommonUtils";
import {Redirect} from "react-router";

function GameContainer({match, loadGame, currentGame, getGameUrl, gameUrl, removeGameUrl, user, isUserLoggingOut}) {
    const isLoading = Object.keys(currentGame).length === 0 || !gameUrl;

    useEffect(() => {
        loadGame(match.params.gameId)
    }, [match.params.gameId]);

    useEffect(() => {
        if (!currentGame.slug) return;
        getGameUrl(currentGame.slug);
        return removeGameUrl;
    }, [currentGame.slug]);

    return (
        <>
            {!user ?
                isUserLoggingOut ?
                    <Redirect to={"/"}/> :
                    <Redirect to={`/login?returnUrl=/games/${match.params.gameId}`}/> :
                <>
                    <section className="wrapper">
                        <div className="game-frame">
                            <div className={classnames("game-container", {loading: isLoading})}>
                                {isLoading
                                    ? <Loading/>
                                    : isMobileDevice() ? window.location = gameUrl :
                                    <iframe allowFullScreen="true"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        className="game"
                                        src={gameUrl}
                                        title={currentGame.name}/>
                                }
                            </div>
                        </div>
                    </section>
                    <InformationSection/>
                </>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        currentGame: state.products.currentGame,
        gameUrl: state.products.gameUrl,
        user: localStorage.getItem("user") || state.user,
        isUserLoggingOut: state.profile.isUserLoggingOut
    };
}

const mapDispatchToProps = {
    removeGameUrl,
    loadGame,
    getGameUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(GameContainer);