export default function socketMiddleware(socket) {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function') return next(action);

        const {event, handle} = action;

        if (!event || typeof event !== "string") return next(action);
        return socket.on(event, handle);
    };
}
