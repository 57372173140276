import initialStore from "../initialStore";
import {
    CLEAR_INFO,
    SET_BANNERS, SET_INFO,
    SET_LOGIN_ERROR,
    SET_MENU,
    SET_REGISTRATION_ERROR, SET_TOP_WINNERS, SHOW_FORGOT_PASSWORD,
    SHOW_LOGIN,
    SHOW_PROFILE,
    SHOW_REGISTRATION, SHOW_RESET_FORGOT_PASSWORD
} from "@actions/actionTypes";
import produce from "immer";
import {isEmpty} from "lodash";

export default function settingsReducer(state = initialStore.settings, action) {
    switch (action.type) {
        case SET_MENU:
            return produce(state, draft => {
                if (isEmpty(action.menuGroup)) return;
                draft.menu[action.menuGroup.name.toLowerCase()].menus = action.menuGroup.menus;
            });
        case SHOW_LOGIN:
            return produce(state, draft => {
                draft.modal.showLogin = action.showLogin || false;
            });
        case SHOW_REGISTRATION:
            return produce(state, draft => {
                draft.modal.showRegistration = action.showRegistration || false;
            });
        case SHOW_PROFILE:
            return produce(state, draft => {
                draft.modal.showProfile = action.showProfile || false;
            });
        case SHOW_FORGOT_PASSWORD:
            return produce(state, draft => {
                draft.modal.showForgotPassword = action.showForgotPassword || false;
            });
        case SHOW_RESET_FORGOT_PASSWORD:
            return produce(state, draft => {
                draft.modal.showResetForgotPassword = action.showResetForgotPassword || false;
            });
        case SET_LOGIN_ERROR:
            return produce(state, draft => {
                draft.errors.loginError = action.message || null;
            });
        case SET_REGISTRATION_ERROR:
            return produce(state, draft => {
                draft.errors.registerError = action.message || null;
            });
        case SET_BANNERS:
            return produce(state, draft => {
                if (isEmpty(action.banners)) return;
                draft.banners = action.banners;
            });
        case SET_TOP_WINNERS:
            return produce(state, draft => {
                if (isEmpty(action.topWinners)) return;
                draft.topWinners = action.topWinners;
            });
        case SET_INFO:
            return produce(state, draft => {
                if (isEmpty(action.info)) return;
                if (action.content) {
                    draft.info["content"] = action.info;
                    return;
                }
                draft.info[action.info.name] = action.info;
            });
        case CLEAR_INFO:
            return produce(state, draft => {
                if (action.content) {
                    draft.info["content"] = null;
                    return;
                }
                draft.info[action.infoName] = null;
            });
        default:
            return state;
    }
}
