import React from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import {logoutUser} from "@actions/userActions";
import userImage from '@assets/img/user-image.jpg'
import {getUserName} from "../../utils/UserUtil";
import {showProfile} from "@actions/settingsActions";

function HeaderUserProfile({user, logoutUser, showProfile}) {
    return (
        <div className="header__profile">
            <div className="profile__card">
                <div className="profile__ava"><img src={user.image || userImage} alt=""/></div>
                <div className="profile__name">
                    <span>
                        <div className="profile__name--username">{getUserName(user)}</div>
                        <div className="profile__name--balance">${user.balance}</div>
                    </span>
                </div>
                <div className="profile__arrow-down" onClick={() => showProfile(true)}></div>
                <a onClick={() => logoutUser()} className="profile__logout">
                    <svg>
                        <use xlinkHref={`${spirites}#logout`}/>
                    </svg>
                </a>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    logoutUser,
    showProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserProfile);
