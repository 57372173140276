import {GetMenu} from "@services/MenuService";
import {SET_MENU} from "@actions/actionTypes";

export function loadMenu(menu) {
    return dispatch => {
        return GetMenu(menu)
            .then(menuGroup => {
                dispatch({type: SET_MENU, menuGroup});
            })
    }
}
