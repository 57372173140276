import React from "react";
import {connect} from 'react-redux';
import {showLogin, showRegistration} from "@actions/settingsActions";
import {FormattedMessage} from "react-intl";

function HeaderLoginContainer({showLogin, showRegistration}) {
    return (
        <>
            <button className="btn header__login--btn btn--outline" onClick={() => showLogin(true)}>
                <span><FormattedMessage id="login"/></span>
            </button>
            <button className="btn header__login--btn btn--primary" onClick={() => showRegistration(true)}>
                <span><FormattedMessage id="signUp"/></span>
            </button>
        </>
    );
}

function mapStateToProps() {
    return {};
}

const mapDispatchToProps = {
    showLogin,
    showRegistration
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLoginContainer);