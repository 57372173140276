import React, {useState} from "react";
import {connect} from "react-redux";
import {changeLanguage} from "@actions/settingsActions";
import classnames from 'classnames';
import spirites from '@assets/img/sprites.svg'

function LanguageSwitcher({languages, selected, changeLanguage, className}) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={className} onClick={() => {setIsActive(!isActive)}}>
            <div className={classnames("header-lang__current", {active: isActive})}>
                <svg className="flag">
                    <use xlinkHref={`${spirites}#flag__${selected.name.toLowerCase()}`}/>
                </svg>
            </div>
            <ul className={classnames("header-lang__list", {active: isActive})}>
                {languages.map(lang => (
                        <li key={lang.id} className="header-lang__list--item" onClick={() => changeLanguage(lang)}>
                            <svg className="flag">
                                <use xlinkHref={`${spirites}#flag__${lang.name.toLowerCase()}`}/>
                            </svg>
                        </li>
                    )
                )}
            </ul>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        languages: state.settings.languages,
        selected: state.settings.lang,
    };
}

const mapDispatchToProps = {
    changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
