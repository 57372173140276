import React from 'react';
import FooterPaymentsList from "./FooterPaymensList";
import FooterMenu from "./FooterMenu";

export default function Footer() {
    return (
        <footer className="footer">
            <FooterPaymentsList/>
            <FooterMenu/>
        </footer>
    );
}
