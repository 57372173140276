export function getUserName(user) {
    if (user.firstName && user.lastName) return `${user.firstName} ${user.lastName}`;
    else if (user.firstName) return user.firstName;
    else if (user.lastName) return user.lastName;
    else if (user.userName) return user.userName;
    else return user.email;
}

export function isEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
