import initialStore from "../initialStore";
import {SET_CATEGORIES, SET_GAME, SET_GAME_URL, SET_GAMES, SET_PRODUCTS_SEARCH_RESULT} from "@actions/actionTypes";
import {isEmpty} from "lodash";
import produce from "immer";

export default function productsReducer(state = initialStore.products, action) {
    switch (action.type) {
        case SET_CATEGORIES:
            return produce(state, draft => {
                if (isEmpty(action.categories)) return;
                draft.categories = action.categories;
            });
        case SET_GAMES:
            return produce(state, draft => {
                draft.games = !isEmpty(action.games) ? action.games : [];
            });
        case SET_GAME:
            return produce(state, draft => {
                draft.currentGame = action.currentGame;
            });
        case SET_GAME_URL:
            return produce(state, draft => {
                draft.gameUrl = action.gameUrl;
            });
        case SET_PRODUCTS_SEARCH_RESULT:
            return produce(state, draft => {
                draft.searchResult = action.games;
            });
        default:
            return state;
    }
}
