import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {verifyAccount} from "@actions/profileActions";
import classnames from "classnames";
import {useIntl} from "react-intl";
import Loading from "../Loading/Loading";

function AccountVerifyPageContainer({location, history, verifyAccount, verificationSuccess}) {
    useEffect(() => {
        verifyAccount(location.search);
    }, [location.search]);

    const intl = useIntl();

    if (verificationSuccess) {
        setTimeout(() => {
            history.push('/');
        }, 5000);
    }
    return (
        <div className={classnames("verify-container", {loading: !verificationSuccess})}>
            {!verificationSuccess
                ? <Loading/>
                : <span className={classnames("infotext", {"success--message": verificationSuccess})}>
                      {verificationSuccess ? intl.formatMessage({id: 'accountVerified'}) : null}
                  </span>
            }
        </div>
    );
}

function mapStateToProps(state) {
    return {
        verificationSuccess: state.profile.verificationSuccess
    }
}

const mapDispatchToProps = {
    verifyAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountVerifyPageContainer);
