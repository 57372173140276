import React, {useState} from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import classnames from 'classnames';
import {isEmpty} from "lodash";
import {FormattedMessage, useIntl} from "react-intl";
import {normalizeComponent} from "../../utils/translationUtil";
import {showForgotPassword} from "@actions/settingsActions";
import {sendForgotPasswordEmail} from "@actions/profileActions";

function ForgotPasswordModal({isOpen, showForgotPassword, sendForgotPasswordEmail, forgotPasswordEmailSent}) {
    if (!isOpen) return null;
    const intl = useIntl();
    const [email, setEmail] = useState("");

    const [isEmailValid, setIsEmailValid] = useState(true);

    function handleEmailChange(event) {
        setEmail(event.target.value);
        setIsEmailValid(!isEmpty(event.target.value));
    }

    function handleConfirmClick() {
        sendForgotPasswordEmail(email);
    }

    return (
        <div className={classnames("modal", "micromodal-slide", {'is-open': isOpen})}
             aria-hidden={isOpen ? "false" : "true"}>
            <div className="modal__overlay" tabIndex="-1">
                <div className="modal__overlay--close"
                     onClick={() => showForgotPassword(false)}/>
                <div
                    className="modal__container"
                    role="dialog"
                    aria-modal="true"
                    aria-label="">
                    <div className="modal__header">
                        <div className="section-text">
                            <svg className="section-icon">
                                <use xlinkHref={`${spirites}#login`}/>
                            </svg>
                            <div className="section-title">
                                {normalizeComponent(intl, "forgotPasswordTitle")}
                            </div>
                        </div>
                        <button className="modal__close" aria-label="" onClick={() => showForgotPassword(false)}/>
                    </div>
                    <div className="modal__content">
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#mail`}/>
                                </svg>
                                <input
                                    type="email"
                                    value={email}
                                    className={classnames("form-control", {"error-background": !isEmailValid})}
                                    placeholder={intl.formatMessage({id: 'email'})}
                                    onChange={handleEmailChange}
                                />
                            </div>
                        </div>
                        {forgotPasswordEmailSent !== null && forgotPasswordEmailSent === true &&
                            <span className="success--message infotext">
                                Success
                            </span>
                        }
                        <div className="forgot-password-btn" onClick={handleConfirmClick}>
                            <FormattedMessage id="confirm"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        isOpen: state.settings.modal.showForgotPassword,
        forgotPasswordEmailSent: state.profile.forgotPasswordEmailSent
    }
}

const mapDispatchToProps = {
    showForgotPassword,
    sendForgotPasswordEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
