import {transform} from 'lodash';

export function transformKeysToLowerCase(data) {
    return transform(data, function (result, val, key) {
        result[key.toLowerCase()] = val;
    })
}

export function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}
