import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Loading from "../Loading/Loading";
import {loginExternal} from "@actions/userActions";

function ExternalLoginContainer({match, history, loginExternal, externalLoginSuccess}) {
    useEffect(() => {
        loginExternal(match.params.provider);
    }, [match.params.provider]);

    if (externalLoginSuccess) {
        history.push('/');
    }
    return (
        <div className="loading verify-container">
            <Loading/>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        externalLoginSuccess: state.profile.externalLoginSuccess
    }
}

const mapDispatchToProps = {
    loginExternal
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLoginContainer);
