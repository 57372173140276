import React, {useEffect} from "react";
import {connect, Provider as ReduxProvider} from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Header from "../Header";
import "@assets/sass/main.sass";
import {initialLoad} from "@actions/initialActions";
import {subscribeStorageChanges} from "@actions/userActions";
import LoginModal from "../LoginModal/LoginModal";
import RegisterModal from "../RegisterModal/RegisterModal";
import ProfileModal from "../ProfileModal/ProfileModal";
import MainContentContainer from "../MainContentContainer";
import Footer from "../Footer/Footer";
import InfoContainer from "../InfoContainer";
import {showLogin, showRegistration, showResetForgotPassword} from "@actions/settingsActions";
import GameContainer from "../GameContainer";
import {IntlProvider} from "react-intl";
import {LanguageCode} from "../../constants/Enums";
import translations from '../../translations'
import AccountVerifyPageContainer from "../AccountVerifyPageContainer/AccountVerifyPageContainer";
import ForgotPasswordModal from "../ForgotPasswordModal/ForgotPasswordModal";
import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
import ExternalLoginContainer from "../ExternalLoginContainer/ExternalLoginContainer";

function App({initialLoad, lang, showRegistration, showLogin, showResetForgotPassword, subscribeStorageChanges}) {
    useEffect(() => {
        initialLoad();
        subscribeStorageChanges();
    }, []);

    return (
        <IntlProvider
            locale={LanguageCode[lang]}
            messages={translations[LanguageCode[lang].toLowerCase()] ? translations[LanguageCode[lang].toLowerCase()] : {}}
            defaultLocale="EN">
            <Router>
                <div className="App">
                    <Header/>
                    <main className="container">
                        <Switch>
                            <Route exact path="/" component={MainContentContainer}/>
                            <Route exact path="/games/:gameId" component={GameContainer}/>
                            <Route exact path="/info/:infoName" component={InfoContainer}/>
                            <Route exact path="/verify" component={AccountVerifyPageContainer}/>
                            <Route exact path="/resetpassword" render={() => {
                                showResetForgotPassword(true);
                                return <MainContentContainer/>
                            }}/>
                            <Route exact path="/register" render={() => {
                                showRegistration(true);
                                return <MainContentContainer/>
                            }}/>
                            <Route exact path="/login" render={() => {
                                showLogin(true);
                                return <MainContentContainer/>
                            }}/>
                            <Route exact path="/callback/:provider" component={ExternalLoginContainer}/>
                            <Route exact path="/:all" render={() => <Redirect to="/"/>}/>
                        </Switch>
                    </main>
                    <Footer/>

                    <LoginModal/>
                    <RegisterModal/>
                    <ProfileModal/>
                    <ForgotPasswordModal/>
                    <ResetPasswordModal/>
                </div>
            </Router>
        </IntlProvider>
    );
}

function mapStateToProps(state) {
    return {
        lang: state.settings.lang.id
    };
}

const mapDispatchToProps = {
    initialLoad,
    subscribeStorageChanges,
    showRegistration,
    showLogin,
    showResetForgotPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
