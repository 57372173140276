export const SET_MENU = "SET_MENU";
export const SET_USER = "SET_USER";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const SHOW_REGISTRATION = "SHOW_REGISTRATION";
export const SHOW_PROFILE = "SHOW_PROFILE";
export const SHOW_FORGOT_PASSWORD = "SHOW_FORGOT_PASSWORD";
export const SHOW_RESET_FORGOT_PASSWORD = "SHOW_RESET_FORGOT_PASSWORD";
export const SET_PROFILE_GAME_HISTORY = "SET_PROFILE_GAME_HISTORY";
export const SET_PROFILE_DEPOSITS_HISTORY = "SET_PROFILE_DEPOSITS_HISTORY";
export const SET_PROFILE_WITHDRAWALS_HISTORY = "SET_PROFILE_WITHDRAWALS_HISTORY";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const SET_PASSWORD_CHANGE_ERROR_MESSAGE = "SET_PASSWORD_CHANGE_ERROR_MESSAGE";
export const SET_FORGOT_PASSWORD_EMAIL_SENT = "SET_FORGOT_PASSWORD_EMAIL_SENT";
export const SET_RESET_PASSWORD_CHANGED = "SET_RESET_PASSWORD_CHANGED";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_REGISTRATION_ERROR = "SET_REGISTRATION_ERROR";
export const SET_BANNERS = "SET_BANNERS";
export const SET_TOP_WINNERS = "SET_TOP_WINNERS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_GAMES = "SET_GAMES";
export const SET_INFO = "SET_INFO";
export const CLEAR_INFO = "CLEAR_INFO";
export const SET_GAME = "SET_GAME";
export const SET_GAME_URL = "SET_GAME_URL";
export const SET_PRODUCTS_SEARCH_RESULT = "SET_PRODUCTS_SEARCH_RESULT";
export const SET_ACCOUNT_VERIFIED = "SET_ACCOUNT_VERIFIED";
export const SUBSCRIBE_USER_UPDATE = "SUBSCRIBE_USER_UPDATE";
export const EXTERNAL_LOGIN_SUCCESS = "EXTERNAL_LOGIN_SUCCESS";
export const ADD_USER_SUBSCRIPTION = "ADD_USER_SUBSCRIPTION";
export const REMOVE_USER_SUBSCRIPTION = "REMOVE_USER_SUBSCRIPTION";
export const SUBSCRIBE_TO_OLD_ROOMS = "SUBSCRIBE_TO_OLD_ROOMS";
export const USER_LOGGING_OUT = "USER_LOGGING_OUT";
