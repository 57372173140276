import initialStore from "../initialStore";
import {
    EXTERNAL_LOGIN_SUCCESS,
    PASSWORD_CHANGE_SUCCESS, SET_ACCOUNT_VERIFIED, SET_FORGOT_PASSWORD_EMAIL_SENT,
    SET_PASSWORD_CHANGE_ERROR_MESSAGE,
    SET_PROFILE_DEPOSITS_HISTORY,
    SET_PROFILE_GAME_HISTORY,
    SET_PROFILE_WITHDRAWALS_HISTORY, SET_RESET_PASSWORD_CHANGED, SET_USER, USER_LOGGING_OUT
} from "@actions/actionTypes";
import {isEmpty} from "lodash";
import produce from "immer";

export default function profileReducer(state = initialStore.profile, action) {
    switch (action.type) {
        case SET_PROFILE_GAME_HISTORY:
            return produce(state, draft => {
                if (isEmpty(action.history)) return;
                draft.gameHistory = action.history;
            });
        case SET_PROFILE_DEPOSITS_HISTORY:
            return produce(state, draft => {
                if (isEmpty(action.history)) return;
                draft.depositsHistory = action.history;
            });
        case SET_PROFILE_WITHDRAWALS_HISTORY:
            return produce(state, draft => {
                if (isEmpty(action.history)) return;
                draft.withdrawalsHistory = action.history;
            });
        case PASSWORD_CHANGE_SUCCESS:
            return produce(state, draft => {
                draft.passwordChangeErrorMessage = null;
                draft.passwordChangeSuccess = action.success;
            });
        case SET_PASSWORD_CHANGE_ERROR_MESSAGE:
            return produce(state, draft => {
                draft.passwordChangeSuccess = false;
                draft.passwordChangeErrorMessage = action.message;
            });
        case SET_ACCOUNT_VERIFIED:
            return produce(state, draft => {
                draft.verificationSuccess = action.success;
            });
        case SET_FORGOT_PASSWORD_EMAIL_SENT:
            return produce(state, draft => {
                draft.forgotPasswordEmailSent = action.success;
            });
        case SET_RESET_PASSWORD_CHANGED:
            return produce(state, draft => {
                draft.resetPasswordChanged = action.success;
            });
        case EXTERNAL_LOGIN_SUCCESS:
            return produce(state, draft => {
                draft.externalLoginSuccess = action.success;
            });
        case SET_USER:
            return produce(state, draft => {
                if (action.user == null)
                    draft.gameHistory = [];
            });
        case USER_LOGGING_OUT:
            return produce(state, draft => {
                draft.isUserLoggingOut = action.value;
            });
        default:
            return state;
    }
}
