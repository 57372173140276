import React from "react";
import LanguageSwitcher from "../LanguageSwitcher";
import SearchBar from "../SearchBar/SearchBar";
import HeaderMenuContainer from "../HeaderMenuContainer/HeaderMenuContainer";
import HeaderUserProfileContainer from "../HeaderUserProfileContainer/HeaderUserProfileContainer";
import {Link} from "react-router-dom";

function Header() {
    return (
        <div className="header">
            <div className="section--wrap">
                <Link to="/" className="sidebar__logo logo"/>
                <LanguageSwitcher className="header__languages"/>
                <SearchBar/>
                <HeaderMenuContainer/>
                <HeaderUserProfileContainer/>
            </div>
        </div>
    );
}

export default Header;