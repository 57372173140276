import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {clearInfo, loadInfo} from "@actions/settingsActions";

function InfoContainer({match, loadInfo, info, clearInfo}) {
    useEffect(() => {
        loadInfo(match.params.infoName, true);
        return () => {
            clearInfo(match.params.infoName, true);
        }
    }, [match.params.infoName]);

    return (
        <div dangerouslySetInnerHTML={{__html: info ? info.description : null}}/>
    );
}

function mapStateToProps(state) {
    return {
        info: state.settings.info.content
    }
}

const mapDispatchToProps = {
    loadInfo,
    clearInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoContainer);
