import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import classnames from "classnames";
import spirites from '@assets/img/sprites.svg'
import {loadInfo} from "@actions/settingsActions";
import {FormattedMessage} from "react-intl";
import Loading from "../Loading/Loading";

function InformationSection({loadInfo, info}) {
    const [license, setLicense] = useState(null);
    useEffect(() => {
        loadInfo("license");
    }, []);

    useEffect(() => {
        if (info && info.description) {
            setLicense(info.description);
        }
    }, [info])

    return (
        <section className="information">
            <div className="section-text">
                <svg className="section-icon">
                    <use xlinkHref={`${spirites}#info`}/>
                </svg>
                <div className="section-title">
                    <FormattedMessage id="onlineCasino"/> - {}
                    <span>
                        <FormattedMessage id="licensedAndRegulated"/>
                    </span>
                </div>
            </div>
            <div className={classnames("information-text", {loading: !license})}>
                {!license
                    ? <Loading/>
                    : <div dangerouslySetInnerHTML={{__html: license}}/>
                }
            </div>
        </section>
    );
}

function mapStateToProps(state) {
    return {
        info: state.settings.info.license
    }
}

const mapDispatchToProps = {
    loadInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationSection);
