import {GetResource, IDP_URL, PostResource, PutResource, UploadFile} from "../utils/HttpUtil";
import {TransactionType} from "../constants/Enums";

export function LoadGameHistory() {
    return GetResource(`/profile/history?transactiontypes=${TransactionType.BET}&transactiontypes=${TransactionType.WIN}&transactiontypes=${TransactionType.REFUND}`)
}

export function LoadDepositsHistory() {
    return GetResource(`/profile/history?transactiontype=${TransactionType.DEPOSIT}`)
}

export function LoadWithdrawalsHistory() {
    return GetResource(`/profile/history?transactiontype=${TransactionType.WITHDRAW}`)
}

export async function UpdateProfile(profileData) {
    if (profileData.image) {
        return UploadFile(profileData.image)
            .then(res => res.json())
            .then(res => {
                profileData.image = res.url
                return PutResource("/profile", profileData)
            });
    }
    return PutResource("/profile", profileData);
}

export function ChangePassword(oldPassword, newPassword, confirmNewPassword) {
    return PutResource("/profile/changepassword", {
        oldPassword,
        newPassword,
        confirmNewPassword
    });
}

export function VerifyAccount(token) {
    return PutResource(`/users/verify/${token}`, null, IDP_URL);
}

export function SendForgotPasswordEmail(email) {
    return PostResource(`/users/forgotpassword`, {email}, IDP_URL);
}
export function ResetForgotPassword(data) {
    return PostResource(`/users/changepassword/${data.token}`, data, IDP_URL);
}
