import initialStore from "../initialStore";
import {SET_USER} from "@actions/actionTypes";

export default function userReducer(state = initialStore.user, action) {
    switch (action.type) {
        case SET_USER:
            return action.user || null;
        default:
            return state;
    }
}
