import {GetToken} from "@services/AuthService";

const API_URL = process.env.REACT_APP_API_PATH;
export const IDP_URL = `${process.env.REACT_APP_API_IDP_PATH}/api/v1`;

export async function GetResource(route, params = {}, api) {
    const url = new URL(`${api || API_URL}${route}`);
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetToken()}`,
        },
    });
    if (res.status !== 200 && res.status !== 404) {
        throw new Error(res.status.toString());
    }
    if (res.status === 404) return {};
    const body = res.body ? await res.json() : null;
    return body.data ? body.data : body;
}

export async function PostResource(route, data, url) {
    if (!url) url = `${API_URL}${route}`;
    else url = `${url}${route}`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetToken()}`,
        },
        body: JSON.stringify(data),
    });
    const body = await res.json();
    if (!res.ok) throw new Error(body.error || body.message);
    return body;
}

export async function PutResource(route, data, url) {
    if(!url) url = `${API_URL}${route}`;
    else url = `${url}${route}`;
    const res = await fetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${GetToken()}`,
        },
        body: JSON.stringify(data),
    });
    const body = await res.json();
    if (!res.ok) throw new Error(body.error || body.message);
    return body;
}

export async function UploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return fetch(`${API_URL}/file`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${GetToken()}`
        },
        body: formData
    });
}
