import {
    ChangePassword,
    LoadDepositsHistory,
    LoadGameHistory,
    LoadWithdrawalsHistory, ResetForgotPassword, SendForgotPasswordEmail,
    UpdateProfile, VerifyAccount
} from "@services/ProfileService";
import {
    PASSWORD_CHANGE_SUCCESS, SET_ACCOUNT_VERIFIED, SET_FORGOT_PASSWORD_EMAIL_SENT, SET_PASSWORD_CHANGE_ERROR_MESSAGE,
    SET_PROFILE_DEPOSITS_HISTORY,
    SET_PROFILE_GAME_HISTORY,
    SET_PROFILE_WITHDRAWALS_HISTORY, SET_RESET_PASSWORD_CHANGED,
    SET_USER
} from "@actions/actionTypes";
import qs from 'query-string';

export function loadGameHistory() {
    return dispatch => {
        return LoadGameHistory()
            .then(data => {
                dispatch({type: SET_PROFILE_GAME_HISTORY, history: data})
            })
    }
}

export function loadDepositsHistory() {
    return dispatch => {
        return LoadDepositsHistory()
            .then(data => {
                dispatch({type: SET_PROFILE_DEPOSITS_HISTORY, history: data})
            })
    }
}

export function loadWithdrawalsHistory() {
    return dispatch => {
        return LoadWithdrawalsHistory()
            .then(data => {
                dispatch({type: SET_PROFILE_WITHDRAWALS_HISTORY, history: data})
            })
    }
}

export function updateProfile(profileData) {
    return dispatch => {
        return UpdateProfile(profileData)
            .then(user => {
                dispatch({type: SET_USER, user});
            })
    }
}

export function changePassword(oldPassword, newPassword, confirmNewPassword) {
    return dispatch => {
        return ChangePassword(oldPassword, newPassword, confirmNewPassword)
            .then(() => {
                dispatch({type: PASSWORD_CHANGE_SUCCESS, success: true});
                setTimeout(() => dispatch({type: PASSWORD_CHANGE_SUCCESS, success: false}), 5000)
            })
            .catch(error => {
                dispatch({type: SET_PASSWORD_CHANGE_ERROR_MESSAGE, message: error.message})
            });
    }
}

export function verifyAccount(tokenQueryString) {
    return dispatch => {
        const query = qs.parse(tokenQueryString);
        return VerifyAccount(query.token)
            .then(data => {
                dispatch({type: SET_ACCOUNT_VERIFIED, success: data.success})
            })
            .catch(() => {
                dispatch({type: SET_ACCOUNT_VERIFIED, success: false})
            })
    }
}

export function sendForgotPasswordEmail(email) {
    return dispatch => {
        return SendForgotPasswordEmail(email)
            .then(data => {
                dispatch({type: SET_FORGOT_PASSWORD_EMAIL_SENT, success: data.success});
                setTimeout(() => {
                    dispatch({type: SET_FORGOT_PASSWORD_EMAIL_SENT, success: null});
                }, 5000);
            })
            .catch(() => {
                dispatch({type: SET_FORGOT_PASSWORD_EMAIL_SENT, success: false})
            })
    }
}

export function resetForgotPassword(data) {
    return dispatch => {
        return ResetForgotPassword(data)
            .then(data => {
                dispatch({type: SET_RESET_PASSWORD_CHANGED, success: data.success});
                setTimeout(() => {
                    dispatch({type: SET_RESET_PASSWORD_CHANGED, success: null});
                }, 5000);
            })
            .catch(() => {
                dispatch({type: SET_RESET_PASSWORD_CHANGED, success: false})
            })
    }
}

