import React, {useState} from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import classnames from 'classnames';
import {FormattedMessage, useIntl} from "react-intl";
import {normalizeComponent} from "../../utils/translationUtil";
import {showForgotPassword, showResetForgotPassword} from "@actions/settingsActions";
import {isEmpty} from "lodash";
import qs from 'query-string';
import {resetForgotPassword} from "@actions/profileActions";

function ResetPasswordModal({isOpen, showResetForgotPassword, resetForgotPassword, resetPasswordChanged}) {
    if (!isOpen) return null;
    const intl = useIntl();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value);
    }

    function handleConfirmClick() {
        setIsPasswordValid(!isEmpty(password) && password.length >= 8);
        setIsConfirmPasswordValid(!isEmpty(confirmPassword) && password === confirmPassword);
        if (isPasswordValid && isConfirmPasswordValid) {
            resetForgotPassword({
                token: qs.parse(window.location.search).token,
                newPassword: password,
                confirmNewPassword: confirmPassword
            });
        }
    }

    if (resetPasswordChanged) {
        setTimeout(() => {
            window.location.href = "/";
        }, 5000);
    }

    return (
        <div className={classnames("modal", "micromodal-slide", {'is-open': isOpen})}
             aria-hidden={isOpen ? "false" : "true"}>
            <div className="modal__overlay" tabIndex="-1">
                <div className="modal__overlay--close"
                     onClick={() => showForgotPassword(false)}/>
                <div
                    className="modal__container"
                    role="dialog"
                    aria-modal="true"
                    aria-label="">
                    <div className="modal__header">
                        <div className="section-text">
                            <svg className="section-icon">
                                <use xlinkHref={`${spirites}#login`}/>
                            </svg>
                            <div className="section-title">
                                {normalizeComponent(intl, "forgotPasswordTitle")}
                            </div>
                        </div>
                        <button className="modal__close" aria-label="" onClick={() => showResetForgotPassword(false)}/>
                    </div>
                    <div className="modal__content">
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#password`}/>
                                </svg>
                                <input
                                    type="password"
                                    className={classnames("form-control", {"error-background": !isPasswordValid})}
                                    placeholder={intl.formatMessage({id: 'password'})}
                                    onChange={handlePasswordChange}
                                    value={password}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#password`}/>
                                </svg>
                                <input
                                    type="password"
                                    className={classnames("form-control", {"error-background": !isConfirmPasswordValid})}
                                    placeholder={intl.formatMessage({id: 'confirmPassword'})}
                                    onChange={handleConfirmPasswordChange}
                                    value={confirmPassword}/>
                            </div>
                        </div>
                        {resetPasswordChanged !== null && resetPasswordChanged === true &&
                            <span className="success--message infotext">
                                Success
                            </span>
                        }
                        <div className="forgot-password-btn" onClick={handleConfirmClick}>
                            <FormattedMessage id="confirm"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        isOpen: state.settings.modal.showResetForgotPassword,
        resetPasswordChanged: state.profile.resetPasswordChanged
    }
}

const mapDispatchToProps = {
    showResetForgotPassword,
    resetForgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);
