import React, {useState} from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import classnames from 'classnames';

import TabProfile from "./TabProfile";
import TabDeposit from "./TabDeposit";
import TabWithdraw from "./TabWithdraw";
import TabSecurity from "./TabSecurity";
import {showProfile} from "@actions/settingsActions";
import {FormattedMessage, useIntl} from "react-intl";
import {normalizeComponent} from "../../utils/translationUtil";

const TAB_PROFILE = 1;
const TAB_DEPOSITS = 2;
const TAB_WITHDRAWALS = 3;
const TAB_SECURITY = 4;

function ProfileModal({user, isOpen, showProfile}) {
    if (!isOpen) return null;
    const [activeTab, setActiveTab] = useState(TAB_PROFILE);
    const intl = useIntl();
    return (
        <div className={classnames("modal", "micromodal-slide", {"is-open": isOpen})} id="modal-profile"
             aria-hidden={isOpen ? "false" : "true"}>
            <div className="modal__overlay" tabIndex="-1">
                <div className="modal__overlay--close"
                     onClick={() => showProfile(false)}/>
                <div
                    className="modal__container"
                    role="dialog"
                    aria-modal="true">
                    <div className="modal__header">
                        <div className="section-text">
                            <svg className="section-icon">
                                <use xlinkHref={`${spirites}#registration`}/>
                            </svg>
                            <div className="section-title">
                                {normalizeComponent(intl, 'userProfile')}
                            </div>
                        </div>
                        <button className="modal__close" aria-label="" onClick={() => showProfile(false)}/>
                    </div>
                    <div className="modal__content">
                        <nav className="games-tabs">
                            <ul className="games-tabs__list">
                                <li
                                    className={classnames("games-tabs__list--item", {active: activeTab === TAB_PROFILE})}
                                    onClick={() => setActiveTab(TAB_PROFILE)}>
                                    <FormattedMessage id="profile"/>
                                </li>
                                <li
                                    className={classnames("games-tabs__list--item", {active: activeTab === TAB_DEPOSITS})}
                                    onClick={() => setActiveTab(TAB_DEPOSITS)}>
                                    <FormattedMessage id="deposits"/>
                                </li>
                                <li
                                    className={classnames("games-tabs__list--item", {active: activeTab === TAB_WITHDRAWALS})}
                                    onClick={() => setActiveTab(TAB_WITHDRAWALS)}>
                                    Withdrawals
                                </li>
                                <li className={classnames("games-tabs__list--item", {active: activeTab === TAB_SECURITY})}
                                    onClick={() => setActiveTab(TAB_SECURITY)}>
                                    <FormattedMessage id="security"/>
                                </li>
                            </ul>
                        </nav>
                        {activeTab === TAB_PROFILE ? <TabProfile user={user}/> : null}
                        {activeTab === TAB_DEPOSITS ? <TabDeposit/> : null}
                        {activeTab === TAB_WITHDRAWALS ? <TabWithdraw/> : null}
                        {activeTab === TAB_SECURITY ? <TabSecurity/> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        isOpen: state.settings.modal.showProfile,
        user: state.user
    }
}

const mapDispatchToProps = {
    showProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);
