import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import classnames from 'classnames';
import {showLogin, showRegistration} from "@actions/settingsActions";
import {isEmpty} from "lodash";
import {registerUser} from "@actions/userActions";
import {isEmail} from "../../utils/UserUtil";
import {FormattedMessage, useIntl} from "react-intl";
import {normalizeComponent} from "../../utils/translationUtil";
import {Link} from "react-router-dom";
import SocialLogin from "../SocialLogin/SocialLogin";

function RegisterModal({isOpen, showRegistration, showLogin, registerUser, registerErrorMessage}) {
    if (!isOpen) return null;
    const intl = useIntl();
    const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isUserNameValid, setIsUserNameValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setErrorMessage(registerErrorMessage);
    }, [registerErrorMessage])

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handleFirstNameChange(event) {
        setFirstName(event.target.value);
    }

    function handleLastNameChange(event) {
        setLastName(event.target.value);
    }

    function handleUserNameChange(event) {
        setUserName(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value);
    }

    function validate() {
        setIsEmailValid(!isEmpty(email) && isEmail(email));
        setIsUserNameValid(!isEmpty(userName));
        setIsPasswordValid(!isEmpty(password) && password.length >= 8);
        setIsConfirmPasswordValid(!isEmpty(confirmPassword) && password === confirmPassword);
        if (!isPolicyAccepted) setErrorMessage("Please accept the policy")
        return isEmailValid && isUserNameValid && isPasswordValid && isConfirmPasswordValid && isPolicyAccepted;
    }

    function handleSignUpClick() {
        if (!validate()) return;
        registerUser({
            password,
            confirmPassword,
            email,
            userName,
            firstName,
            lastName
        });
    }

    return (
        <div className={classnames("modal", "micromodal-slide", {"is-open": isOpen})} id="modal-signup"
             aria-hidden={isOpen ? "false" : "true"}>
            <div className="modal__overlay" tabIndex="-1">
                <div className="modal__overlay--close"
                     onClick={() => showRegistration(false)}/>
                <div
                    className="modal__container"
                    role="dialog"
                    aria-modal="true"
                    aria-label="">
                    <div className="modal__header">
                        <div className="section-text">
                            <svg className="section-icon">
                                <use xlinkHref={`${spirites}#registration`}/>
                            </svg>
                            <div className="section-title">
                                {normalizeComponent(intl, "signUp")}
                            </div>
                        </div>
                        <button className="modal__close" aria-label="" onClick={() => showRegistration(false)}/>
                    </div>
                    <div className="modal__content">
                        <span className="error--message infotext">
                            {errorMessage}
                        </span>
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#mail`}/>
                                </svg>
                                <input
                                    type="email"
                                    className={classnames("form-control", {"error-background": !isEmailValid})}
                                    placeholder={intl.formatMessage({id: 'email'})}
                                    onChange={handleEmailChange}
                                    value={email}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#info`}/>
                                </svg>
                                <input
                                    type="text"
                                    className={classnames("form-control", {"error-background": !isUserNameValid})}
                                    placeholder={intl.formatMessage({id: 'username'})}
                                    onChange={handleUserNameChange}
                                    value={userName}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#password`}/>
                                </svg>
                                <input
                                    type="password"
                                    className={classnames("form-control", {"error-background": !isPasswordValid})}
                                    placeholder={intl.formatMessage({id: 'password'})}
                                    onChange={handlePasswordChange}
                                    value={password}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#password`}/>
                                </svg>
                                <input
                                    type="password"
                                    className={classnames("form-control", {"error-background": !isConfirmPasswordValid})}
                                    placeholder={intl.formatMessage({id: 'confirmPassword'})}
                                    onChange={handleConfirmPasswordChange}
                                    value={confirmPassword}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-checkbox">
                                <input className="form-checkbox__btn" type="checkbox" id="registration__politica"
                                       name="checkbox" value="3" checked={isPolicyAccepted}
                                       onChange={(e) => setIsPolicyAccepted(e.target.checked)}/>
                                <label htmlFor="registration__politica">
                                    <FormattedMessage id="iAcceptThe"/> {}
                                    <Link to="/info/terms" onClick={() => showRegistration(false)}>
                                        <FormattedMessage id="termsOfUse"/>
                                    </Link>
                                    {' & '}
                                    <Link to="/info/privacy" onClick={() => showRegistration(false)}>
                                        <FormattedMessage id="privacyPolicy"/>
                                    </Link>
                                </label>
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn--primary"
                                onClick={handleSignUpClick}>
                                <FormattedMessage id="signUp"/>
                            </button>
                            <button
                                className="btn btn--secondary"
                                onClick={() => {
                                    showRegistration(false);
                                    showLogin(true)
                                }}>
                                <FormattedMessage id="logIn"/>
                            </button>
                            <SocialLogin/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        isOpen: state.settings.modal.showRegistration,
        registerErrorMessage: state.settings.errors.registerError
    }
}

const mapDispatchToProps = {
    showRegistration,
    showLogin,
    registerUser
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);
