import React, {useState} from 'react';
import {connect} from 'react-redux';
import spirites from '@assets/img/sprites.svg'
import classnames from 'classnames';
import {showForgotPassword, showLogin, showRegistration} from "@actions/settingsActions";
import {loginUser} from "@actions/userActions";
import {isEmpty} from "lodash";
import {FormattedMessage, useIntl} from "react-intl";
import {normalizeComponent} from "../../utils/translationUtil";
import SocialLogin from "../SocialLogin/SocialLogin";
import qs from 'query-string';
import {Redirect} from "react-router";

function LoginModal({isOpen, showLogin, loginUser, showRegistration, showForgotPassword, loginErrorMessage, user}) {
    const returnUrl = qs.parse(window.location.search).returnUrl;
    if (!isOpen || (user && !returnUrl)) return null;
    const intl = useIntl();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);

    function handleEmailChange(event) {
        setEmail(event.target.value);
        setIsEmailValid(!isEmpty(event.target.value));
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
        setIsPasswordValid(!isEmpty(event.target.value));
    }

    function handleLoginClick() {
        if (!isEmpty(email) && !isEmpty(password)) {
            loginUser(email, password);
        } else {
            setIsEmailValid(false);
            setIsPasswordValid(false);
        }
    }

    return (
        <>
            {returnUrl && user ?
                <Redirect to={returnUrl}/> :
                <div className={classnames("modal", "micromodal-slide", {'is-open': isOpen})} id="modal-login"
                aria-hidden={isOpen ? "false" : "true"}>
                <div className="modal__overlay" tabIndex="-1">
                <div className="modal__overlay--close"
                     onClick={() => showLogin(false)}/>
                <div
                    className="modal__container"
                    role="dialog"
                    aria-modal="true"
                    aria-label=""
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                    <div className="modal__header">
                        <div className="section-text">
                            <svg className="section-icon">
                                <use xlinkHref={`${spirites}#login`}/>
                            </svg>
                            <div className="section-title">
                                {normalizeComponent(intl, "logIn")}
                            </div>
                        </div>
                        <button className="modal__close" aria-label="" onClick={() => showLogin(false)}/>
                    </div>
                    <div className="modal__content">
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#mail`}/>
                                </svg>
                                <input
                                    type="email"
                                    value={email}
                                    className={classnames("form-control", {"error-background": !isEmailValid || loginErrorMessage})}
                                    placeholder={intl.formatMessage({id: 'email'})}
                                    onChange={handleEmailChange}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-input">
                                <svg className="form-icon">
                                    <use xlinkHref={`${spirites}#password`}/>
                                </svg>
                                <input
                                    type="password"
                                    value={password}
                                    className={classnames("form-control", {"error-background": !isPasswordValid || loginErrorMessage})}
                                    placeholder={intl.formatMessage({id: 'password'})}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                        </div>
                        <span className="error--message infotext">
                            {loginErrorMessage}
                        </span>
                        <div className="form-group">
                            <div className="form-text last">
                                <a onClick={() => {
                                    showLogin(false);
                                    showForgotPassword(true);
                                }}>
                                    <FormattedMessage id="forgotPassword"/>
                                </a>
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn--primary"
                                onClick={handleLoginClick}>
                                <FormattedMessage id="logIn"/>
                            </button>
                            <button
                                className="btn btn--secondary"
                                onClick={() => {
                                    showLogin(false);
                                    showRegistration(true);
                                }}>
                                <FormattedMessage id="signUp"/>
                            </button>
                            <SocialLogin/>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        isOpen: state.settings.modal.showLogin,
        loginErrorMessage: state.settings.errors.loginError,
        user: state.user
    }
}

const mapDispatchToProps = {
    showLogin,
    loginUser,
    showRegistration,
    showForgotPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);