import {combineReducers} from "redux";
import settings from "./settingsReducer";
import user from "./userReducer";
import profile from "./profileReducer";
import products from "./productsReducer";
import socketSubscription from "./socketSubscriptionReducer";

const rootReducer = combineReducers({
    settings,
    user,
    profile,
    products,
    socketSubscription
});

export default rootReducer;
