export const Language = {
    ENGLISH: 1,
    RUSSIAN: 3
};

export const LanguageName = {
    1: "English",
    3: "Russian"
};

export const LanguageCode = {
    1: "EN",
    3: "RU"
};

export const TransactionType = {
    BET: 0,
    WIN: 1,
    LOST: 2,
    REFUND: 3,
    BONUS: 4,
    DEPOSIT: 5,
    WITHDRAW: 6
}

export const TransactionCode = {
    0: "Bet",
    1: "Win",
    2: "Lost",
    3: "Refund",
    5: "Bonus",
    6: "Deposit",
    7: "Withdraw"
}

export const SubscriptionType = {
    USER: 1
};
