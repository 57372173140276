import {
    CLEAR_INFO,
    SET_INFO,
    SHOW_FORGOT_PASSWORD,
    SHOW_LOGIN,
    SHOW_PROFILE,
    SHOW_REGISTRATION, SHOW_RESET_FORGOT_PASSWORD
} from "@actions/actionTypes";
import {LoadInfo} from "@services/InfoService";

export function changeLanguage(lang) {
    localStorage.setItem("lang", lang.id);
    window.location.reload(false);
}

export function showLogin(showLogin) {
    return dispatch => {
        dispatch({type: SHOW_LOGIN, showLogin});
    }
}

export function showRegistration(showRegistration) {
    return dispatch => {
        dispatch({type: SHOW_REGISTRATION, showRegistration});
    }
}

export function showProfile(showProfile) {
    return dispatch => {
        dispatch({type: SHOW_PROFILE, showProfile});
    }
}

export function showForgotPassword(showForgotPassword) {
    return dispatch => {
        dispatch({type: SHOW_FORGOT_PASSWORD, showForgotPassword});
    }
}

export function showResetForgotPassword(showResetForgotPassword) {
    return dispatch => {
        dispatch({type: SHOW_RESET_FORGOT_PASSWORD, showResetForgotPassword});
    }
}

export function loadInfo(infoName, content) {
    return dispatch => {
        return LoadInfo(infoName)
            .then(info => {
                dispatch({type: SET_INFO, info, content});
            })
    }
}

export function clearInfo(infoName, content) {
    return dispatch => {
        dispatch({type: CLEAR_INFO, infoName, content});
    }
}
