import React, {useState}from "react";
import {connect} from "react-redux";
import spirites from '@assets/img/sprites.svg'
import {searchProducts} from "@actions/productActions";
import SearchBarList from "./SearchBarList"
import {isEmpty} from "lodash";
import classnames from "classnames";

function SearchBar({searchProducts, searchResults}) {
    const [isActive, setIsActive] = useState(false);
    
    function handleInputChange(e) {
        const searchQuery = e.target.value;
        if (searchQuery.length >= 3) {
            searchProducts(searchQuery)
        }
    }

    return (
        <div className={classnames("header__search", {active: isActive})}>
            <form action="#" className="header-search">
                <button className="btn header-search__btn">
                    <svg>
                        <use xlinkHref={`${spirites}#header__search`}/>
                    </svg>
                </button>
                <input
                    type="text"
                    onChange={handleInputChange}
                    onBlur={() => { setIsActive(false) }}
                    onFocus={() => { setIsActive(true) }}
                    className="form-controls header-search__field"/>
            </form>
           {<SearchBarList />}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        searchResults: state.products.searchResult
    };
}

const mapDispatchToProps = {
    searchProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
