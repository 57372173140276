import React, {useEffect} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {loadWithdrawalsHistory} from "@actions/profileActions";
import {FormattedMessage} from "react-intl";

function TabWithdraw({withdrawalsHistory, loadWithdrawalsHistory}) {
    useEffect(() => {
        loadWithdrawalsHistory()
    }, []);

    return (
        <div className="tab withdraw">
            <div className="right">
                <div className="table">
                    <div className="table-header">
                        <div className="table-header__item">
                            #
                        </div>
                        <div className="table-header__item">
                            <FormattedMessage id="sum"/>
                        </div>
                        <div className="table-header__item">
                            <FormattedMessage id="date"/>
                        </div>
                        <div className="table-header__item">
                            <FormattedMessage id="system"/>
                        </div>
                    </div>
                    {withdrawalsHistory.map((history, index) => {
                        return (
                            <div key={history.id} className="table-body">
                                <div className="table-body__item num">
                                    <span>{index + 1}</span>
                                </div>
                                <div className="table-body__item">
                                    ${history.balanceBefore - history.balanceAfter}
                                </div>
                                <div className="table-body__item">
                                    {moment(history.date).format("DD.MM.YYYY")}
                                </div>
                                <div className="table-body__item">
                                    {history.productName}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        withdrawalsHistory: state.profile.withdrawalsHistory
    }
}

const mapDispatchToProps = {
    loadWithdrawalsHistory
}

export default connect(mapStateToProps, mapDispatchToProps)(TabWithdraw)
