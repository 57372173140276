import {GetResource} from "../utils/HttpUtil";

export function LoadCategories() {
    return GetResource("/categories");
}

export function LoadGames(categoryId) {
    let url = "/products";
    if (categoryId) url = url.concat(`?categoryId=${categoryId}`);
    return GetResource(url);
}

export function LoadGame(gameId) {
    return GetResource(`/products/${gameId}`);
}

export function GetGameUrl(gameSlug) {
    return GetResource(`/${gameSlug}/start`);
}

export function Search(searchQuery) {
    return GetResource(`/products?name=${searchQuery}`);
}
