import React from "react";
import {Link} from "react-router-dom";

export function GameItem({id, name, description, image, isLoggedIn}) {
    return (
        <Link to={isLoggedIn ? `/games/${id}` : `/login?returnUrl=/games/${id}`} className="games-item" onClick={() => window.scrollTo({top: 0})}>
            <div className="games-item__info">
                <div className="games-item__title">{name}</div>
                <div className="games-item__description">
                    {description}
                </div>
            </div>
            <img src={image} alt=""/>
        </Link>
    );
}
