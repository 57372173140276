import React from "react";
import paypal from "@assets/img/payments/payment-paypal.png";
import visa from "@assets/img/payments/payment-visa.png";
import mastercard from "@assets/img/payments/payment-mastercard.png";
import mir from "@assets/img/payments/payment-mir.png";
import yandex from "@assets/img/payments/payment-yandex.png";
import qiwi from "@assets/img/payments/payment-qiwi.png";
import webmoney from "@assets/img/payments/payment-webmoney.png";

export default function FooterPaymentsList() {
    return (
        <div className="payments-list">
            <div className="payments-list__item">
                <img src={paypal} alt="PayPal"/>
            </div>
            <div className="payments-list__item">
                <img src={visa} alt="Visa"/></div>
            <div className="payments-list__item">
                <img src={mastercard} alt="MasterCard"/>
            </div>
            <div className="payments-list__item">
                <img src={mir} alt="Mir"/>
            </div>
            <div className="payments-list__item">
                <img src={yandex} alt="YandexMoney"/>
            </div>
            <div className="payments-list__item">
                <img src={qiwi} alt="Qiwi"/>
            </div>
            <div className="payments-list__item">
                <img src={webmoney} alt="Webmoney"/>
            </div>
        </div>
    );
}