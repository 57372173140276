import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {loadCategories, loadGames} from "@actions/productActions";
import classnames from "classnames";

function CategoriesContainer({loadCategories, categories, loadGames}) {
    const [activeCategoryId, setActiveCategoryId] = useState(0);

    useEffect(() => {
        loadCategories();
    }, []);

    useEffect(() => {
        const firstCategory = categories[0];
        if (!firstCategory) return;
        setActiveCategory(firstCategory);
    }, [categories]);

    function setActiveCategory(category) {
        setActiveCategoryId(category.id);
        if (category.name === "All Games") {
            loadGames();
        } else {
            loadGames(category.id);
        }
    }

    function handleCategoryClick(categoryId) {
        setActiveCategory(categories.find(c => c.id === categoryId));
    }

    return (
        <div className="section-top">
            <nav className="games-tabs">
                <ul className="games-tabs__list">
                    {categories.map(category => {
                        return (
                            <li key={category.id}
                                className={classnames("games-tabs__list--item", {active: activeCategoryId === category.id})}
                                onClick={() => handleCategoryClick(category.id)}
                            >
                                {category.name}
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        categories: state.products.categories
    }
}

const mapDispatchToProps = {
    loadCategories,
    loadGames
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesContainer);
