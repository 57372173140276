import React from 'react';
import {connect} from "react-redux";
import spirites from '@assets/img/sprites.svg'
import CategoriesContainer from "../CategoriesContainer/CategoriesContainer";
import GamesList from "./GamesList";
import {FormattedMessage} from "react-intl";

function GamesContainer() {
    return (
        <section className="games">
            <div className="section-text">
                <svg className="section-icon">
                    <use xlinkHref={`${spirites}#blackjack`}/>
                </svg>
                <div className="section-title">
                    <FormattedMessage id="our"/> { }
                    <span>
                        <FormattedMessage id="games"/>
                    </span>
                </div>
            </div>
            <CategoriesContainer/>
            <GamesList/>
        </section>
    );
}

function mapStateToProps() {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GamesContainer);
