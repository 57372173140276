import React from 'react';
import BannersContainer from "../BannersContainer/BannersContainer";
import GamesContainer from "../GamesContainer/GamesContainer";
import InformationSection from "../InformationSection/InformationSection";

export default function MainContentContainer() {
    return (
        <>
            <BannersContainer/>
            <GamesContainer/>
            <InformationSection/>
        </>
    );
}