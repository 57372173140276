import React from 'react';
import {connect} from "react-redux";
import BannersSection from "../BannersSection/BannersSection";
import TopWinnersSection from "../TopWinnersSection/TopWinnersSection";
import Loading from "../Loading/Loading";

function BannersContainer() {
    return (
        <section className="wrapper">
            <BannersSection/>
            <TopWinnersSection/>
        </section>
    );
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BannersContainer);
